import { useState, useEffect, useRef } from 'react';
import './App.css';
import './App-dark.css';
import OctoOcto from './OCTOOCTO.png';

function App() {
  const [text, setText] = useState("");
  const [prevText, setPrevText] = useState("");
  const [response, setResponse] = useState("");
  const [prevResponse, setPrevResponse] = useState("");
  const [wikipedia, setWikipedia] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (text === "") {
      setResponse("");
      setWikipedia("");
    }
  }, [text]);

  useEffect(() => {
    async function warmUpBackend() {
      await fetch('https://encyclopedia-backend5000000000000000.vercel.app/warm-up');
    }
    warmUpBackend();

    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleDarkModeChange = (e) => {
      if (e.matches) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    };
    darkModeMediaQuery.addEventListener("change", handleDarkModeChange);

    if (darkModeMediaQuery.matches) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }

    return () => {
      darkModeMediaQuery.removeEventListener("change", handleDarkModeChange);
    };
  }, []);

  async function checkWikipediaPage() {
    // const data = await fetch("http://localhost:3001/get-response", { //local
    const url = `https://en.wikipedia.org/w/api.php?action=query&format=json&titles=${text}&origin=*`; //global
    const response = await fetch(url);
    const data = await response.json();

    if (!data.query.pages[-1]) {
      setWikipedia(text);
    } else {
      setWikipedia("");
    }
  }
  async function handleSearchButtonClick() 
  {
    if (text === prevText) {
      setResponse(prevResponse);
      checkWikipediaPage();
    }
    
    else{
      if (text !== "") {
        setResponse("");
        setWikipedia("");
        setIsLoading(true);
        const data = await fetch("https://encyclopedia-backend5000000000000000.vercel.app/get-response", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text: text }),
        })
          .then((data) => data.json());

        let str = data.response.trim();
        setResponse(str);
        setPrevResponse(str);
        setIsLoading(false);
        setPrevText(text);
        checkWikipediaPage();
      }
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchButtonClick();
    }
  }

  function cutTextUntilPeriod(text) {
    let lastIndex = text.lastIndexOf('.');

    if (lastIndex === -1) {
      return text;
    }

    return text.slice(0, lastIndex + 1);
  }

  return (
    <div className="App">
      <header>
        <br />
        <br />
        <h1>Encyclopedia</h1>
        <br />
      </header>
      <main>
        <div>
          <textarea
            className="text-area"
            onChange={(e) => setText(e.target.value.toLowerCase().replace(/\s/g, ''))}
            onKeyDown={handleKeyPress}
            onFocus={(e) => {
              setTimeout(() => {
                requestAnimationFrame(() => {
                  e.target.select();
                });
              }, 0);
              e.target.placeholder = '';
            }}
            onBlur={(e) => {
              if (e.target.value === '') {
                e.target.placeholder = 'enter a term...';
              }
            }}
            placeholder='enter a term...'
            rows={1}
            maxLength={18}
          />
          <br />
          <br />
          <br />
          <button className="search-button" onClick={handleSearchButtonClick}>Search</button>
          <br />
          {isLoading ? <h5>Loading...</h5> : null}
          {response !== "" ? (
            <h4 className={(response.length < 28) ? "h4center" : ""}>
              {cutTextUntilPeriod(response)}
            </h4>
          ) : null}
          {wikipedia !== "" ? <h5><a href={`https://en.wikipedia.org/wiki/${wikipedia}`} target="_blank" rel="noopener noreferrer">wikipedia.org/wiki/{wikipedia}</a></h5> : null}
        </div>

        <div className="octo-octo-logo">
          <a href="https://octoocto.net/projects/encyclopedia">
            <img src={OctoOcto} alt="contact"></img>
          </a>
        </div>

      </main>
    </div>
  );
}

export default App;
